import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { HttpUtilsService } from '../http/http-utils.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGoogleService {
  private oAuthService = inject(OAuthService);
  private router = inject(Router);

  constructor(
    private httpUtils: HttpUtilsService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.initConfiguration();
    }
  }

  initConfiguration() {
    const authConfig: AuthConfig = {
      issuer: 'https://accounts.google.com',
      strictDiscoveryDocumentValidation: false,
      clientId:
        '544534011813-uj78bk7nfic4olgf1qtf2ktsa6kkh1qf.apps.googleusercontent.com',
      redirectUri: window.location.origin + '/auth/google-auth-callback',
      scope: 'openid profile email',
    };

    this.oAuthService.configure(authConfig);
    this.oAuthService.setupAutomaticSilentRefresh();
    this.oAuthService.loadDiscoveryDocumentAndTryLogin();
  }

  login() {
    this.oAuthService.initImplicitFlow();
  }

  logout() {
    this.oAuthService.revokeTokenAndLogout();
    this.oAuthService.logOut();
  }

  getProfile() {
    const profile = this.oAuthService.getIdentityClaims();
    const idToken = this.oAuthService.getIdToken();
    console.log('profile', profile);
    console.log('idToken', idToken);
    return { profile, idToken };
  }

  getToken() {
    return this.oAuthService.getAccessToken();
  }

  loginCallback(callbackData: any) {
    this.httpUtils.post('/auth/google-auth-callback', callbackData).subscribe({
      next: (response: any) => {
        if (response.accessToken && response.refreshToken) {
          this.authService.setUserId(response.userId);
          this.authService.setAccessToken(response.accessToken);
          this.authService.setRefreshToken(response.refreshToken);
          let returnUrl = this.router.parseUrl(this.router.url).queryParams[
            'return_url'
          ];
          if (!returnUrl) {
            returnUrl = '/';
          }
          this.router.navigate([returnUrl]);
        }
      },
      error: (error) => {},
      complete: () => {},
    });
  }
}
